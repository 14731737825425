import { HubLayoutWithToolMenu } from "@/components/layouts/HubLayout";
import type { NextPageWithLayout } from "@/pages/_app";
import { Col, Row, Typography } from "antd";
const {
  Title,
  Text
} = Typography;
export interface ErrorPageProps {
  title?: string;
  subtitle?: string;
  body?: string;
}
export const ErrorPage: NextPageWithLayout = ({
  title,
  subtitle,
  body
}: ErrorPageProps) => {
  return <Row style={{
    textAlign: "center",
    margin: "2rem auto 0",
    maxWidth: "540px"
  }} data-sentry-element="Row" data-sentry-component="ErrorPage" data-sentry-source-file="404.tsx">
      <Col span={24} data-sentry-element="Col" data-sentry-source-file="404.tsx">
        {title !== "" && <Title style={{
        marginBottom: "0"
      }}>{title || "Page Not Found"}</Title>}
        {subtitle !== "" && <Text type="secondary">{subtitle || "Error 404"}</Text>}
        {body !== "" && <Title level={3}>
            {body || "Contact the System Administrator, or use the navigation to find what you need."}
          </Title>}
      </Col>
    </Row>;
};
ErrorPage.getLayout = page => {
  return <HubLayoutWithToolMenu>{page}</HubLayoutWithToolMenu>;
};
export default ErrorPage;